$(document).ready(function () {

    $('#list-items').sortable({
        axis: 'y',
        update: function () {
            var data_to_send = $('#list-items').sortable("serialize");
            $.ajax({
                type: "GET",
                dataType: "JSON",
                url: "order/ajax",
                data: data_to_send,
            });
            $('.load-page').fadeIn('fast');
            location.reload();
        }
    });


    $('form').submit(function () {
        $('.load-page').fadeIn('fast');
    });


    $('div.msg-all').delay(3500).fadeOut('normal');

    $('a#envia').click(function () {
        $('.load-page').fadeIn('fast');
    })


    $('#datatabless').DataTable({
        "autoWidth": true,
        "ordering": false,
        "language": {
            //"url": "dataTables.portuguese-brasil.lang",
            "sEmptyTable": "Nenhum registro encontrado",
            "sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
            "sInfoFiltered": "(Filtrados de _MAX_ registros)",
            "sInfoPostFix": "",
            "sInfoThousands": ".",
            "sLengthMenu": "_MENU_ Resultados por página",
            "sLoadingRecords": "Carregando...",
            "sProcessing": "Processando...",
            "sZeroRecords": "Nenhum registro encontrado",
            "sSearch": "Pesquisar ",
            "oPaginate": {
                "sNext": "Próximo",
                "sPrevious": "Anterior",
                "sFirst": "Primeiro",
                "sLast": "Último"
            },
            "oAria": {
                "sSortAscending": ": Ordenar colunas de forma ascendente",
                "sSortDescending": ": Ordenar colunas de forma descendente"
            }
        }
    });

    $(".textarea").wysihtml5();

});
